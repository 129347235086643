import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 95px;
`;

export const LogoWrapper = styled.div`
  svg {
    width: 42px;
  }
`;
